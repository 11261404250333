import { graphql } from 'gatsby';
import React from 'react';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import CTA from '../components/CTA';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import MediaText from '../components/MediaText';
import SquareMediaText from '../components/SquareMediaText';
import TestimonyCarouselGrid from '../components/TestimonyCarouselGrid';
import { VideoHero } from '../components/VideoHero';
import defaultPicture from '../images/PellerinGuitares_110.jpeg';
import { TextAnimate } from '../components/TextAnimate';

const translations = [
  {
    locale: 'en',
    url: '/en/',
  },
];

const headerTitleStyle = {
  fontSize:
    'clamp(3.5rem, calc(1.75rem + (5.5 - 1.75) * ((100vw - 20rem) / (98 - 20))), 5.5rem)',
  lineHeight: 'calc((96 / 88) * 0.95em)',
};

export default function AccueilPage({ data, location }) {
  return (
    <Layout
      translations={translations}
      location={location}
      logoOptions={{ inverted: true }}
    >
      <article className="pt-56 lg:pt-96 space-y-28">
        <VideoHero queue={['https://vimeo.com/1028404657']}>
          <p
            style={headerTitleStyle}
            className="text-indigo font-serif font-semibold text-center"
          >
            <TextAnimate
              segmentClassName="bg-gradient-to-r from-yellow-800 via-indigo-900 to-yellow-900 bg-[position:_0%_0%] bg-[size:_200%_auto] text-transparent bg-clip-text animate-move-text-bg"
              animation="fadeIn"
              duration={1}
              by="line"
              as="span"
              once
            >
              {`Là où l'art\nrésonne avec\nla musique.`}
            </TextAnimate>
          </p>
        </VideoHero>

        <section className="container text-xl text-center min-h-[68vh] flex items-center justify-center">
          <div>
            <Heading level={2} className="text-5xl">
              Une guitare parfaitement <br />
              accordée à vos goûts
            </Heading>

            <p className="max-w-prose my-4">
              Parce que vous êtes unique, vous méritez une guitare personnalisée
              qui vous fera vibrer. Le son, le design, les fréquences, tout vous
              fera dire&nbsp;: «&thinsp;J’ai enfin trouvé ma guitare de
              rêve!&thinsp;»
            </p>

            <UnderlinedButton to="/guitares-personnalisees" className="my-5">
              S’offrir une guitare personnalisée
            </UnderlinedButton>
          </div>
        </section>

        <MediaText left top media={data.pellerinSoundImage}>
          <div className="max-w-prose">
            <Heading level={2} className="!mt-0">
              Le son Pellerin&nbsp;: <br />
              en harmonie avec l’excellence
            </Heading>

            <p>
              Découvrez des guitares parfaitement équilibrées dans les hautes et
              les basses fréquences. Des matériaux haut de gamme, choisis et
              assemblés à la main avec soin, c’est ce qui confère aux guitares
              Pellerin un son de classe mondiale.
            </p>
          </div>
        </MediaText>

        <CTA>
          <Heading level={2} className="!mt-0">
            Des guitares qui rockent
          </Heading>

          <p className="max-w-prose my-4">
            À travers les années, Michel Pellerin et son acolyte François
            C.Paradis ont imaginé et conçu plus de 250 guitares distinctives.
            Quoique le terme œuvre d’art est plus approprié, voici un aperçu des
            plus belles guitares Pellerin.
          </p>

          <UnderlinedButton to="/realisations" className="text-xl my-5">
            Voir les créations
          </UnderlinedButton>
        </CTA>

        <section>
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                La note parfaite des mélomanes
              </Heading>

              <p className="max-w-prose mx-auto my-5">
                Ceux qui possèdent une guitare Pellerin le savent, ce n’est pas
                un instrument ordinaire, c’est une extension de votre corps et
                de votre esprit. Un pur joyau. Et surtout, une expérience
                phénoménale du début à la fin.
              </p>
            </div>
          </div>

          <TestimonyCarouselGrid className="my-12" />

          <div className="container text-center">
            <UnderlinedButton to="/temoignages" className="text-xl my-5">
              Lire les témoignages
            </UnderlinedButton>
          </div>
        </section>

        <section className="container">
          <SquareMediaText
            src={defaultPicture}
            className="wide-content-contained"
            media={data.readyToPlayImage.childImageSharp}
          >
            <Heading level={2} className="!mt-0">
              Des guitares prêtes à jouer pour jazzer votre&nbsp;vie
            </Heading>

            <p>
              Michel Pellerin est tellement passionné par la création de
              guitares exceptionnelles qu’il en confectionne aussi pour le
              plaisir. Et c’est vous qui en profitez, car vous pouvez vous
              offrir un instrument exceptionnel sans attendre 2 ans pour un
              instrument personnalisé.
            </p>

            <UnderlinedButton to="/guitares-pretes-a-jouer" className="text-xl">
              Jeter un œil aux guitares à vendre.
            </UnderlinedButton>
          </SquareMediaText>

          <SquareMediaText
            src={defaultPicture}
            className="wide-content-contained"
            media={data.reputationImage.childImageSharp}
            left
          >
            <Heading level={2} className="!mt-0">
              Un maitre qui perfectionne son art depuis&nbsp;1998
            </Heading>

            <p>
              Le luthier Michel Pellerin est au sommet de son art. Dans son
              atelier, à Thetford Mines au Québec, entouré de morceaux de bois,
              de cordes et d’outils, il s’applique. Sa mission est simple&nbsp;:
              créer un instrument unique de qualité supérieure qui prendra vie
              entre vos mains.
            </p>

            <UnderlinedButton to="/a-propos" className="text-xl">
              Admirer l’artisan à l’œuvre.
            </UnderlinedButton>
          </SquareMediaText>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    pellerinSoundImage: file(name: { eq: "PellerinGuitares_040" }) {
      childImageSharp {
        gatsbyImageData(width: 1536, placeholder: BLURRED)
      }
    }

    readyToPlayImage: file(name: { eq: "PellerinGuitares_025" }) {
      childImageSharp {
        gatsbyImageData(width: 768, height: 768, placeholder: BLURRED)
      }
    }

    reputationImage: file(name: { eq: "PellerinGuitares_134" }) {
      childImageSharp {
        gatsbyImageData(width: 768, height: 768, placeholder: BLURRED)
      }
    }
  }
`;
